import { Component, Input, OnInit } from '@angular/core';

import { getStorageLanguage, LANGUAGES } from '../extra';
import { ONBOARDING_ACTION } from './guide.constant';
import { ActiveOnboarding, IOnboarding } from './guide.model';

@Component({
  selector: 'bvl-guide-message',
  templateUrl: './guide-message.component.html'
})

export class GuideMessageComponent implements OnInit {

  @Input() process: IOnboarding;
  @Input() position: any;

  TEXT = LANGUAGES[getStorageLanguage()];

  ONBOARDING_ACTION = ONBOARDING_ACTION;

  constructor(
    private _activeOnboarding: ActiveOnboarding
  ) { }

  ngOnInit(): void { }

  endGuide(): void {
    this._activeOnboarding.setAction(this.ONBOARDING_ACTION.end);
  }

  changeGuide(action): void {
    this._activeOnboarding.setAction(action);
  }
}
