export class ActiveOnboarding {

  setAction(action?: any): void { }

}

export interface IOnboarding {
  index?: number;
  totalGuide?: number;
  title: string;
  description: string;
  image?: string;
  position?: string;
  target?: string;
  click?: string;
}
