import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule } from '../button';
import { GuideMessageComponent } from './guide-message.component';

@NgModule({
  imports: [CommonModule, ButtonModule],
  declarations: [GuideMessageComponent],
  exports: [GuideMessageComponent],
  entryComponents: [GuideMessageComponent]
})
export class GuideMessageModule { }
