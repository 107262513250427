import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

const getPosition = el => {

  let x = 0,
      y = 0;

  while (el != null && (el.tagName || '').toLowerCase() !== 'html') {
      x += el.offsetLeft || 0;
      y += el.offsetTop || 0;
      el = el.parentElement;
  }

  return { x: parseInt(`${x}`, 10), y: parseInt(`${y}`, 10) };
}

@Directive({
  selector: '[bvlShowScroll]'
})
export class ShowScrollDirective implements OnInit {

  @Input() movement = 'top';
  @Input() transition = '1.5s';

  constructor(private _elementRef: ElementRef) { }

  @HostListener('window:scroll', ['$event']) handler(event: Event): void { }

  ngOnInit(): void {
    const { nativeElement } = this._elementRef;

    nativeElement.classList.add('show-scroll');
    nativeElement.classList.add(this.movement);
    nativeElement.style.transition = this.transition;

    this.handler = () => {
      const { top } = nativeElement.getBoundingClientRect();

      if (top < 500) {
        nativeElement.classList.add('show');
        this.handler = () => {};
      }
    };
  }

}
