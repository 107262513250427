export const ONBOARDING_ACTION = {
  init: 'init',
  next: 'next',
  prev: 'prev',
  end: 'end'
};

export const GUIDES: Array<any> = [
  {
    path: '/',
    showIntro: true,
    process: [
      {
        target: 'bvl-header .g-site-header--content .left .onboarding-findsab',
        title: 'Sociedades Agente de Bolsa',
        description: `Toda la información sobre las SAB (Sociedades Agentes de Bolsa) y su ubicación en el mapa.`,
        position: 'bottom',
        hideMobile: true
      },
      {
        target: 'bvl-header .g-site-header--content .right .onboarding-search',
        title: 'Buscador web',
        description: `Puedes filtrar aquí todas las páginas de nuestra nueva web con la(s) palabras clave que desees.`,
        position: 'bottom'
      },
      {
        target: 'bvl-header .g-site-header--content .right .onboarding-message',
        title: 'Contáctanos',
        description: `Si tienes alguna duda, consulta o relacionado, puedes enviarnos un mensaje y te responderemos en breve.`,
        position: 'bottom'
      },
      {
        target: 'bvl-header .g-site-header--content .right .onboarding-profile',
        title: 'Cuenta personalizada BVL',
        description: `Ponemos a tu disposición varios beneficios siendo parte de la comunidad BVL, descúbrelos aquí.`,
        position: 'bottom'
      },
      {
        target: 'bvl-header bvl-switch-language .onboarding-language-switcher',
        title: 'Cambiador de Idioma',
        description: `Activa el switch para cambiar el idioma de español a inglés.`,
        position: 'bottom',
        hideMobile: true
      },
      {
        target: 'bvl-today .onboarding-filters',
        title: 'Información a tu medida',
        description: `Define lo que quieres encontrar en la nueva web según lo que necesites.`,
        position: 'right'
      },
      {
        target: 'bvl-today .onboarding-operations',
        title: 'Detalle del día actual',
        description: `Aquí podrás ver la actividad en la rueda de bolsa negociada al día actual: Nro. De operaciones y monto negociado.`,
        position: 'left'
      },
      {
        target: 'bvl-home-table .onboarding-home-table',
        title: 'Cotización del día',
        description: `Encuentra el movimiento en detalle de las empresas cotizadas al día, alzas y bajas
                      y el monto negociado. También puedes añadir cualquier empresa a tu Watchlist.`,
        position: 'right',
        click: 'tbody tr:first-of-type td:first-of-type'
      },
      {
        target: 'bvl-indices .onboarding-indices',
        title: 'Movimiento Índices S&P/BVL',
        description: `Conoce los últimos movimientos de los índices S&P/BVL. Conoce todos los índices entrando al link “Ver todos”.`,
        position: 'left'
      },
      {
        target: 'bvl-news bvl-filters .btn-group-toggle',
        title: 'Las noticias más importantes',
        description: `Aquí podrás encontrar los avisos de interés y los hechos de importancia de cada empresa.
                      Si deseas saber por empresa o período, sólo da clic al botón “Ver todo”.`,
        position: 'left',
        hideMobile: true
      },
      {
        target: 'bvl-items-grid .grid-wrapper.variant-basic',
        title: 'Reportes BVL',
        description: `Descargar la información diaria, mensual, anual y los estados financieros comparados últimos actualizados.`,
        position: 'right'
      },
      {
        target: 'bvl-bag-play > .container',
        title: 'Construye tu mundo financiero con Bolsa Play',
        description: `Mantente al día y aprende con el programa de la propia Bolsa de Valores de Lima.
                      No olvides suscribirte a nuestro canal.`,
        position: 'bottom'
      },
      {
        target: 'bvl-items-grid .grid-wrapper.grid-dark',
        title: 'Aprende a invertir',
        description: `Te dejamos 4 herramientas con las que puedes conocer y entrar al mundo de la inversión en la BVL`,
        position: 'right'
      },
      {
        target: 'bvl-real-time .content',
        title: 'Alzas y bajas de empresas',
        description: `Aquí podrás visualizar la variación de las empresas cotizadas al día actual.`,
        position: 'top',
        hideMobile: true
      }
    ]
  },
  {
    path: '/emisores/detalle',
    process: [
      {
        target: 'bvl-issuer-details .btn-set button',
        title: 'Watchlist personalizado',
        description: `Con tu cuenta personalizada BVL puedes añadir las empresas de tu interés al
                      Watchlist para verlas primero y dentro de tu perfil BVL.`,
        position: 'bottom'
      },
      {
        target: 'bvl-details-chart .head-detail-chart .select-container',
        title: 'Lista de acciones por empresa',
        description: `Conoce el detalle por cada valor que las empresas poseen para que puedas analizarlas individualmente.`,
        position: 'bottom'
      },
      {
        target: 'bvl-details-chart .page-section.dark',
        title: 'Detalle de lista de acciones',
        description: `Aquí verás el historial del valor en el tiempo.`,
        position: 'bottom'
      }
    ]
  },
  {
    path: '/emisores/empresa-en-bolsa',
    process: [
      {
        target: 'bvl-site-menu .g-site-submenu a.active',
        title: 'Cómo listar en bolsa',
        description: `Aquí encontrarás todo lo que debes saber para que tu empresa pueda listar en la Bolsa de Valores de Lima.`,
        position: 'bottom',
        hideMobile: true
      }
    ]
  },
  {
    path: '/emisores/noticias-emisores',
    process: [
      {
        target: 'bvl-site-menu .g-site-submenu a.active',
        title: 'Bolsa News',
        description: `Aquí encontrarás los hechos de importancia, Estados Financieros y Memorias de todas las empresas.`,
        position: 'bottom',
        hideMobile: true
      },
      {
        target: 'bvl-tab-fidecomisos bvl-tabs ul li:nth-child(1)',
        title: 'Hechos de importancia',
        description: `Te aparecerán los hechos de importancia del día. Sin embargo,
                      puedes filtrarlos por período, empresa y tipo de asunto.`,
        position: 'bottom',
        hideMobile: true
      },
      {
        target: 'bvl-tab-fidecomisos bvl-tabs ul li:nth-child(2)',
        title: 'Estados Financieros',
        description: `Puedes obtenerlos por Emisores, Fondos de inversión y patrimonios de fideicomiso.
                      De igual manera filtrarlos por empresa, tipo y período.`,
        position: 'bottom',
        hideMobile: true
      },
      {
        target: 'bvl-tab-fidecomisos bvl-tabs ul li:nth-child(3)',
        title: 'Memorias',
        description: `Puedes obtenerlos por Emisores y Fondos de inversión. Puedes filtrarlos por empresa y ejercicio.`,
        position: 'bottom',
        hideMobile: true
      },
      {
        target: 'bvl-tab-fidecomisos > div > bvl-tabs > div',
        title: 'La información que necesitas',
        description: `
          Aquí encontrarás los hechos de importancia,
          los Estados Financieros y las Memorias de los emisores.
          Puedes filtrarlos por período, emisor y tipo de asunto.
        `,
        position: 'bottom',
        hideDesktop: true
      }
    ]
  },
  {
    path: '/mercado/resumen-mercado',
    process: [
      {
        target: 'bvl-resume-chart .time-filter .btn-group-toggle',
        title: 'Resumen de Mercado',
        description: `Puedes encontrar la información por día, mes, 3 meses,
                      6 meses y por año de la actividad del mercado en la Bolsa.`,
        position: 'right',
        hideMobile: true
      },
      {
        target: 'bvl-resume-chart .highcharts-contextmenu',
        title: 'Herramientas del gráfico',
        description: `Ponemos a disposición 4 herramientas para analizar el gráfico:
                      <ol>
                        <li>Zoom in</li>
                        <li>Zoom out</li>
                        <li>Captura pantalla</li>
                        <li>Exportar PDF</li>
                      </ol>`,
        position: 'left'
      }
    ]
  },
  {
    path: '/mercado/agentes/listado',
    process: [
      {
        target: 'bvl-toolbar .btn.btn-link',
        title: 'Encuentra la SAB más cercana',
        description: `Al presionar este botón, se activará la geolocalización para ordenar la lista de SAB más cercanas a tu ubicación.`,
        position: 'bottom'
      }
    ]
  },
  {
    path: '/productos/publicaciones',
    process: [
      {
        target: 'bvl-toolbar',
        title: 'Publicaciones BVL',
        description: `Puedes encontrar aquí los boletines diarios, estados
                      financieros comparados e información bursátil. Puedes filtrar por año.`,
        position: 'bottom'
      },
      {
        target: 'bvl-publications .box',
        title: 'Detalle de búsqueda',
        description: `Aquí te aparecerán los detalles de la búsqueda realizada en la barra superior.
                      Abre el colapsable y encontrarás la información.`,
        position: 'bottom'
      }
    ]
  }
];
