import { environment } from '@bvl-admin/environments/environment';

export class ConfigurationEndpoint {
  public static configuration = `${environment.LOAD_BALANCER}/configurations`;
  public static saveSiteIdentity = `${environment.LOAD_BALANCER}/configurations/general`;
  public static saveSocialNetworking = `${environment.LOAD_BALANCER}/configurations/social-networking`;
  public static saveMetadata = `${environment.LOAD_BALANCER}/configurations/metadata-general`;

  public static saveKeys = `${environment.LOAD_BALANCER}/configurations/keys`;
}
