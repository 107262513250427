import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { UnsubscribeOnDestroy } from '../../../../extra/utils/unsubscribe-on-destroy';
import { BUTTON_ACTIONS, MODAL_BREAKPOINT } from './../constants/modal.constants';
import { IModalConfig } from './../models/modal.interface';

@Component({
  selector: 'bvl-shared-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalComponent extends UnsubscribeOnDestroy implements OnInit {

  @Output() buttonActionEvent = new EventEmitter();

  activeModal: Boolean;
  config: IModalConfig;

  notifyActive: boolean;

  BUTTON_ACTIONS = BUTTON_ACTIONS;
  MODAL_BREAKPOINT = MODAL_BREAKPOINT;

  constructor() {
    super();
  }

  ngOnInit(): void { }

  /**
   * Emit output buttonActionEvent to subscribe it in the modalRef (models/modal-ref)
   */
  buttonAction(actionType: string): void {
    this.buttonActionEvent.emit(actionType);
  }

}
