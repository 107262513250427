module.exports =  {
  prod: {
    code: 'prod',
    production: true,
    API_URL: 'https://dataondemand.bvl.com.pe',
    LOAD_BALANCER: 'https://loadbalancer.grupobvl.com.pe',
    WC_BUNDLE_PATH: 'assets/web-components',
    CULQI_PUBLIC_KEY: 'pk_live_wj0R0TJga6NGsUdN',
    AWS_AMPLIFY_CONFIG: {
      aws_project_region: 'us-east-1',
      aws_cognito_identity_pool_id: 'us-east-1:1fa2d157-8523-464e-9c39-0677669baf90',
      aws_cognito_region: 'us-east-1',
      aws_user_pools_id: 'us-east-1_6vXIBar5n',
      aws_user_pools_web_client_id: '3s2d949kitbet7tjm6tmaknc08',
      oauth: {}
    },
    RECAPTCHA_KEY: '6LcqWMcUAAAAAL-4TUqqi06t-A6wTZVi__AZVaGg'
  },
  dev: {
    code: 'dev',
    production: false,
    API_URL: 'https://dataondemand.bvl-develop.com',
    LOAD_BALANCER: 'https://loadbalancer.holding.bvl-develop.com',
    WC_BUNDLE_PATH: 'assets/web-components/dev',
    CULQI_PUBLIC_KEY: 'pk_test_kI7Rs2cG8iJhI3gl',
    AWS_AMPLIFY_CONFIG: {
      aws_project_region: 'us-east-1',
      aws_cognito_identity_pool_id: 'us-east-1:74df0dbd-7b3c-498f-b727-1c7a209fac7e',
      aws_cognito_region: 'us-east-1',
      aws_user_pools_id: 'us-east-1_dXQGE6iIE',
      aws_user_pools_web_client_id: '61o3aqajcobu40v0tl42svbqid',
      oauth: {}
    },
    RECAPTCHA_KEY: '6LcqWMcUAAAAAL-4TUqqi06t-A6wTZVi__AZVaGg'
  },
  qa: {
    code: 'qa',
    production: false,
    API_URL: 'https://dataondemand.bvl-release.com',
    LOAD_BALANCER: 'https://loadbalancer.holding.bvl-release.com',
    WC_BUNDLE_PATH: 'assets/web-components/qa',
    CULQI_PUBLIC_KEY: 'pk_test_YGpTKMjuUl6GNYGs',
    AWS_AMPLIFY_CONFIG: {
      aws_project_region: 'us-east-1',
      aws_cognito_identity_pool_id: 'us-east-1:a6c11a3f-3311-4a6a-8d20-a51bd6fe8a42',
      aws_cognito_region: 'us-east-1',
      aws_user_pools_id: 'us-east-1_2Rx16q6jQ',
      aws_user_pools_web_client_id: '1j4t7vqkgoar45lo6cav8hi7sm',
      oauth: {}
    },
    RECAPTCHA_KEY: '6LcqWMcUAAAAAL-4TUqqi06t-A6wTZVi__AZVaGg'
  }
 };
