import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DomChangeDirectiveModule } from '../extra';
import { SliderItemDirective } from './slider-item.directive';
import { SliderComponent } from './slider.component';

@NgModule({
  declarations: [SliderComponent, SliderItemDirective],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    DomChangeDirectiveModule
  ],
  exports: [SliderComponent, SliderItemDirective]
})
export class SliderModule { }
