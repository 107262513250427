import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ShowScrollDirective } from './show-scroll.directive';

@NgModule({
  declarations: [ShowScrollDirective],
  imports: [
    CommonModule
  ],
  exports: [ShowScrollDirective]
})
export class ShowScrollModule { }
