import { environment } from '@bvl-admin/environments/environment';

export class PageEndpoint {
  public static siteMenu = `${environment.LOAD_BALANCER}/admin/page/listMenuOptions`;
  public static pagesList = `${environment.LOAD_BALANCER}/trays/page/list`;
  public static approvalsList = `${environment.LOAD_BALANCER}/trays/approbations/list`;
  public static templateList = `${environment.LOAD_BALANCER}/trays/template/list`;
  public static templateByCode = `${environment.LOAD_BALANCER}/trays/page/loadViewTemplateForEdition/{templateCode}`;
  public static savePage = `${environment.LOAD_BALANCER}/trays/page/save`;
  public static pageById = `${environment.LOAD_BALANCER}/trays/page/getPage/{idPage}`;
  public static pagePublishedById = `${environment.LOAD_BALANCER}/trays/page/getPublishing/{idPage}`;
  public static categoriesList = `${environment.LOAD_BALANCER}/pageHeader/categories`;
  public static editPage = `${environment.LOAD_BALANCER}/trays/page/editPage/{idPage}`;
  public static publishPage = `${environment.LOAD_BALANCER}/trays/page/publish`;
  public static requestPublication = `${environment.LOAD_BALANCER}/trays/page/requestPublishing`;
  public static cancelPageChanges = `${environment.LOAD_BALANCER}/trays/page/cancelPage`;
  public static observePage = `${environment.LOAD_BALANCER}/trays/page/observedPage`;
  public static revertPage = `${environment.LOAD_BALANCER}/trays/page/revertPage`;
  public static pageHisotry = `${environment.LOAD_BALANCER}/page/history/list/{idPage}`;
  public static restorePageHisotry = `${environment.LOAD_BALANCER}/page/history/restore/{id}`;
  public static getPagesAutocopleteByPath = `${environment.LOAD_BALANCER}/trays/page/autocomplete`;
  public static getPagesAutocopleteByPathAndTitle = `${environment.LOAD_BALANCER}/trays/page/autocomplete/filters`;
  public static deletePage = `${environment.LOAD_BALANCER}/trays/page/deletePage/{idPage}`;
}
