import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { checkIfAudio, checkIfVideo, IMediaPlayer } from './media-player';

@Component({
  selector: 'bvl-media-player',
  templateUrl: './media-player.component.html',
  styleUrls: ['./media-player.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MediaPlayerComponent implements OnInit {

  media: IMediaPlayer = {
    url: '',
    title: ''
  };

  isVideo;
  isAudio;

  showPlayer = false;
  data: any;

  constructor(private _sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    document.addEventListener('init-media', ev => {
      const { detail } = (ev) as any;
      const { data } = detail;
      const itsSameData = this.data && JSON.stringify(data) === JSON.stringify(this.data);

      if (data && !itsSameData) {
        this.showPlayer = true;
        this.media = {};
        this.data = data;

        this.isVideo = checkIfVideo(this.data.url)
        this.isAudio = checkIfAudio(this.data.url);

        this._setMedia();
        this._sendNotification(this.data);
      }
    });
  }

  private _setMedia(): void {
    this.media = this.data;
  }

  private _sendNotification(data): void {
    const event = new CustomEvent('media-player', {
      detail: { data }
    });
    document.dispatchEvent(event);
  }

  endMedia(): void {
    this.showPlayer = false;
    this.data = {};
    this._sendNotification(false);
  }
}
