import { Injectable } from '@angular/core';
import {
  DISTINCTFUNC,
  RULES_SEO,
  RULES_SEO_CODE,
  RULES_SEO_VALID,
  SPLITFUNC
} from '@bvl-admin/settings/constants/rules-seo.constant';
import { IConfiguration } from '@bvl-admin/statemanagement/models/configuration.interface';
import { IMetadata, IPageByLanguage } from '@bvl-admin/statemanagement/models/page.interface';
import {
  IRuleSeoConfiguration,
  IVariableTitleContent,
  SEOConfiguration
} from '@bvl-admin/statemanagement/models/seo.interface';
import { StringUtil } from '@bvl-core/shared/helpers/util';
import { BehaviorSubject } from 'rxjs';

export const months = {
  es: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
  en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
};

@Injectable({
  providedIn: 'root'
})
export class SEOService {

  private seoConfigurationSource = new BehaviorSubject<SEOConfiguration>(new SEOConfiguration());
  configuration$ = this.seoConfigurationSource.asObservable();

  constructor() {
  }

  updateData(data: SEOConfiguration): void {
    this.seoConfigurationSource.next(data);
  }

  generateTitle(data: SEOConfiguration): string {
    let title = '';
    if (data.titleContent.length) {
      for (const content of data.titleContent) {
        title += content.variable ? ` ${this.getVariable(data, content.content)}` : ` ${content.content || ''}`;
      }
    }

    return title.trim()
      .replace(/\s\s+/g, ' ');
  }

  private getTitle(data: SEOConfiguration): string {
    const ret = new RegExp('\%titulo\%', 'gi');
    const res = new RegExp('\%separador\%', 'gi');
    const resn = new RegExp('\%titulo del sitio\%', 'gi');

    let title = (data.titleSnippet || '').trim()
      .replace(/\s\s+/g, ' ');

    title = title.replace(res, this.getVariable(data, 'separador'));
    title = title.replace(ret, this.getVariable(data, 'titulo'));
    title = title.replace(resn, this.getVariable(data, 'titulo del sitio'));

    return title;
  }

  private getVariable(data: SEOConfiguration, variable: string): string {
    let content: string;

    switch (variable.toLowerCase()) {
      case 'separador':
        content = '-';
        break;
      case 'titulo del sitio':
        content = data.siteName || '';
        break;
      case 'titulo':
        content = data.pageTitle || '';
        break;
      case 'fecha':
        const d = new Date();
        content = `${d.getDay()} ${months[data.language][d.getMonth()]}, ${d.getHours()}`;
        break;
      default:
        content = '';
        break;
    }

    return content;
  }

  addVariable(variable: IVariableTitleContent): void {
    const currentData = this.seoConfigurationSource.getValue();
    currentData.titleContent.push(variable);
    currentData.title = this.generateTitle(currentData);
    currentData.rules = this.getConfigureBasicInformation(currentData);
    this.updateData(currentData);
  }

  removeVariable(index: number): void {
    const currentData = this.seoConfigurationSource.getValue();
    currentData.titleContent = [...currentData.titleContent.splice(0, index), ...currentData.titleContent.splice(index + 1)];
    currentData.title = this.generateTitle(currentData);
    currentData.rules = this.getConfigureBasicInformation(currentData);
    this.updateData(currentData);
  }

  setVariable(content: string, index: number): void {
    const currentData = this.seoConfigurationSource.getValue();
    // if (content) {
    //   const re = new RegExp(`\%(separador|titulo del sitio|titulo|fecha)\%`, 'gi');
    //   if (content.match(re)) {
    //     const els: Array<{ text: string; index: number; lastIndex: number; }> = [];
    //     let f = null;
    //     while (!f) {
    //       const g = re.exec(content);
    //       if (!g) { f = 'finalize';  break; }
    //       els.push({ text: g[0], index: g.index, lastIndex: re.lastIndex });
    //     }

    //     els.forEach(el => {
    //       const firts = content.substr(el.index, el.lastIndex);
    //     });
    //   }
    // } else {
    //   currentData.titleContent[index].content = content;
    // }

    currentData.title = this.generateTitle(currentData);
    currentData.rules = this.getConfigureBasicInformation(currentData);
    this.updateData(currentData);
  }

  setInfo(
    urlSite: string,
    page: IPageByLanguage,
    contentHtml: Element,
    defaultConfiguration: IConfiguration): void {
      const currentData = this.seoConfigurationSource.getValue();
      currentData.siteName = defaultConfiguration.sitename;
      currentData.urlSite = urlSite;
      currentData.pageTitle = page.title;
      currentData.slug = page.pagePath;
      currentData.contentHtml = contentHtml;
      currentData.language = page.language;
      currentData.titleSnippet = page.metadata.titleSnippet || defaultConfiguration.metadata.titleSnippet;
      currentData.description = page.metadata.description;
      currentData.social.ogTitle = page.metadata.ogTitle;
      currentData.social.ogUrl = page.metadata.ogUrl;
      currentData.social.ogDescription = page.metadata.ogDescription;
      currentData.social.ogImage = page.metadata.ogImage;
      currentData.social.twitterTitle = page.metadata.twitterTitle;
      currentData.social.twitterDescription = page.metadata.twitterDescription;
      currentData.social.twitterImage = page.metadata.twitterImage;
      currentData.canonicalLink = page.metadata.canonicalLink;
      currentData.focusKeyword = page.metadata.keywords;
      currentData.descriptionSite = defaultConfiguration.metadata.description;
      currentData.noindex = page.metadata.noindex;
      currentData.noindexSite = defaultConfiguration.metadata.noindex;
      currentData.nofollow = page.metadata.nofollow || defaultConfiguration.metadata.nofollow;
      currentData.avancedRobots = page.metadata.avancedRobots || defaultConfiguration.metadata.avancedRobots || [];
      currentData.title = this.getTitle(currentData);
      currentData.rules = this.getConfigureBasicInformation(currentData);
      this.updateData(currentData);
  }

  setInfoSite(sitename: string, urlSite: string, description: string): void {
    const currentData = this.seoConfigurationSource.getValue();
    currentData.siteName = sitename;
    currentData.urlSite = urlSite;
    currentData.descriptionSite = description;
    currentData.title = this.generateTitle(currentData);
    currentData.rules = this.getConfigureBasicInformation(currentData);
    this.updateData(currentData);
  }

  setBasic(basic: { title: string, description: string, slug: string }): void {
    const currentData = this.seoConfigurationSource.getValue();
    currentData.title = basic.title;
    currentData.description = basic.description;
    currentData.slug = basic.slug;
    currentData.rules = this.getConfigureBasicInformation(currentData);
    this.updateData(currentData);
  }

  setTitle(title: string): void {
    const currentData = this.seoConfigurationSource.getValue();
    currentData.titleSnippet = title;
    currentData.title = this.getTitle(currentData);
    currentData.rules = this.getConfigureBasicInformation(currentData);
    this.updateData(currentData);
  }

  setPageTitle(pageTitle: string): void {
    const currentData = this.seoConfigurationSource.getValue();
    currentData.pageTitle = pageTitle;
    currentData.title = this.getTitle(currentData);
    currentData.rules = this.getConfigureBasicInformation(currentData);
    this.updateData(currentData);
  }

  setPageTitleSlug(pageTitle: string, slug: string): void {
    const currentData = this.seoConfigurationSource.getValue();
    currentData.pageTitle = pageTitle;
    currentData.title = this.getTitle(currentData);
    currentData.slug = slug;
    currentData.rules = this.getConfigureBasicInformation(currentData);
    this.updateData(currentData);
  }

  setSlug(slug: string): void {
    const currentData = this.seoConfigurationSource.getValue();
    currentData.slug = slug;
    currentData.rules = this.getConfigureBasicInformation(currentData);
    this.updateData(currentData);
  }

  setCanonical(canonical: string): void {
    const currentData = this.seoConfigurationSource.getValue();
    currentData.canonicalLink = canonical;
    this.updateData(currentData);
  }

  setDescription(description: string): void {
    const currentData = this.seoConfigurationSource.getValue();
    currentData.description = description;
    currentData.rules = this.getConfigureBasicInformation(currentData);
    this.updateData(currentData);
  }

  setTitleSocial(title: string, isOg = true): void {
    const currentData = this.seoConfigurationSource.getValue();
    currentData.social[isOg ? 'ogTitle' : 'twitterTitle'] = title;
    this.updateData(currentData);
  }

  setDescriptionSocial(description: string, isOg = true): void {
    const currentData = this.seoConfigurationSource.getValue();
    currentData.social[isOg ? 'ogDescription' : 'twitterDescription'] = description;
    this.updateData(currentData);
  }

  setImageSocial(image: string, isOg = true): void {
    const currentData = this.seoConfigurationSource.getValue();
    currentData.social[isOg ? 'ogImage' : 'twitterImage'] = image;
    this.updateData(currentData);
  }

  setKeywords(word: { focusKeyword?: string, secondaryKeywords?: string }): void {
    const currentData = this.seoConfigurationSource.getValue();
    if (word.focusKeyword) { currentData.focusKeyword = word.focusKeyword; }
    if (word.secondaryKeywords) { currentData.secondaryKeywords = word.secondaryKeywords; }
    currentData.rules = this.getConfigureBasicInformation(currentData);
    this.updateData(currentData);
  }

  setNoIndex(noIndex: string): void {
    const currentData = this.seoConfigurationSource.getValue();
    currentData.noindex = noIndex;
    this.updateData(currentData);
  }

  setNoFollow(noFollow: string): void {
    const currentData = this.seoConfigurationSource.getValue();
    currentData.nofollow = noFollow;
    this.updateData(currentData);
  }

  setAvancedRobots(avancedRobots: Array<string>): void {
    const currentData = this.seoConfigurationSource.getValue();
    currentData.avancedRobots = avancedRobots;
    this.updateData(currentData);
  }

  private getConfigureBasicInformation(data: SEOConfiguration): Array<IRuleSeoConfiguration> {
    let rules = [];
    rules = rules.concat(this.rulesGeneral(data));
    rules = rules.concat(this.rulesTitle(data));
    rules = rules.concat(this.rulesMetaDescription(data));
    rules = rules.concat(this.rulesHtmlContent(data));

    return rules;
  }

  private rulesGeneral(data: SEOConfiguration): Array<IRuleSeoConfiguration> {
    const rules: Array<IRuleSeoConfiguration> = [];
    const keywords = data.focusKeyword || '';
    if (!keywords.trim()) {
      rules.push({
        code: 'GEN1',
        // tslint:disable-next-line:max-line-length
        description: 'Ingrese una palabra clave de enfoque en el campo Palabra clave al comienzo del formulario (Ingrese la palabra clave de enfoque)',
        value: false
      } as IRuleSeoConfiguration);
    }

    return rules;
  }

  private rulesTitle(data: SEOConfiguration): Array<IRuleSeoConfiguration> {
    const rules: Array<IRuleSeoConfiguration> = [];
    const title = (data && data.title) || '';
    const rule1 = RULES_SEO.find(r => r.code === RULES_SEO_CODE.existPageTitle);
    rules.push({
      code: rule1.code,
      description: !!title ? rule1.description : rule1.descriptionError,
      value: !!title
    } as IRuleSeoConfiguration);

    const keywords = `${(data.focusKeyword) || ''} ${(data.secondaryKeywords) || ''}`
    .replace(/[&\/\\#,+()-;^$~%.":*?<>{}]/gmi, '')
    .trim();
    if (keywords) {
      const rule2 = RULES_SEO.find(r => r.code === RULES_SEO_CODE.focusKeywordIsUedInPageTitle);
      const wordsAll = SPLITFUNC(keywords.toLocaleLowerCase());
      const words = wordsAll.filter(DISTINCTFUNC);
      const reWordsAll = new RegExp(`\\b(${words.join('|')})\\b`, 'gmi');
      const matchWords = title.toLocaleLowerCase()
        .match(reWordsAll) || [];
      const valid2 = matchWords.filter(DISTINCTFUNC).length === words.length;
      const desc2 = valid2 ? rule2.description : rule2.descriptionError;
      rules.push({
        code: rule2.code,
        description: desc2.replace(/{{focusKeyword}}/g, keywords),
        value: valid2
      } as IRuleSeoConfiguration);

      // const rule3 = RULES_SEO.find(r => r.code === RULES_SEO_CODE.focusKeywordIsUsedBeginningInPageTitle);

    }

    const rule4 = RULES_SEO.find(r => r.code === RULES_SEO_CODE.pageTitleLength);
    const valid4 = RULES_SEO_VALID.pageTitleMinLength < data.titleLength && data.titleLength < RULES_SEO_VALID.pageTitleMaxLength;
    const desc4 = valid4 ? rule4.description : rule4.descriptionError;
    rules.push({
      code: rule4.code,
      description: desc4.replace(/{{aviable}}/g, (RULES_SEO_VALID.pageTitleMaxLength - data.titleLength).toString())
        .replace(/{{length}}/g, data.titleLength.toString())
        .replace(/{{maxlength}}/g, RULES_SEO_VALID.pageTitleMaxLength.toString()),
      value: valid4
    } as IRuleSeoConfiguration);

    return rules;
  }

  private rulesMetaDescription(data: SEOConfiguration): Array<IRuleSeoConfiguration> {
    const rules: Array<IRuleSeoConfiguration> = [];
    const description = (data && data.description) || '';
    const rule1 = RULES_SEO.find(r => r.code === RULES_SEO_CODE.existMetaDescription);
    rules.push({
      code: rule1.code,
      description: !!description ? rule1.description : rule1.descriptionError,
      value: !!description
    } as IRuleSeoConfiguration);

    const rule2 = RULES_SEO.find(r => r.code === RULES_SEO_CODE.focusKeywordIsUedInMetaDescription);
    const keywords = `${(data.focusKeyword) || ''} ${(data.secondaryKeywords) || ''}`
    .replace(/[&\/\\#,+()-;^$~%.":*?<>{}]/gmi, '')
    .trim();
    if (keywords) {
      const wordsAll = SPLITFUNC(keywords.toLocaleLowerCase());
      const words = wordsAll.filter(DISTINCTFUNC);
      const reWordsAll = new RegExp(`\\b(${words.join('|')})\\b`, 'gmi');
      const matchWords = description.toLocaleLowerCase()
        .match(reWordsAll) || [];
      const valid2 = matchWords.filter(DISTINCTFUNC).length === words.length;
      const desc2 = valid2 ? rule2.description : rule2.descriptionError;
      rules.push({
        code: rule2.code,
        description: desc2.replace(/{{focusKeyword}}/g, keywords),
        value: valid2
      } as IRuleSeoConfiguration);

    }

    // const rule3 = RULES_SEO.find(r => r.code === RULES_SEO_CODE.focusKeywordIsUsedBeginningInPageTitle);

    const rule4 = RULES_SEO.find(r => r.code === RULES_SEO_CODE.metaDescriptionLength);
    // tslint:disable-next-line:max-line-length
    const valid4 = RULES_SEO_VALID.metaDescriptionMinLength < data.descriptionLength && data.descriptionLength < RULES_SEO_VALID.metaDescriptionMaxlength;
    const desc4 = valid4 ? rule4.description : rule4.descriptionError;
    rules.push({
      code: rule4.code,
      description: desc4.replace(/{{aviable}}/g, (RULES_SEO_VALID.metaDescriptionMaxlength - data.descriptionLength).toString())
        .replace(/{{length}}/g, description.length.toString())
        .replace(/{{maxlength}}/g, RULES_SEO_VALID.metaDescriptionMaxlength.toString()),
      value: valid4
    } as IRuleSeoConfiguration);

    return rules;
  }

  private _existKeywordsInContent(keywords: string, textContent: string): boolean {
    keywords = keywords
                .replace(/[&\/\\#,+()-;^$~%.":*?<>{}]/gmi, '')
                .trim();

    const wordsAll = SPLITFUNC(keywords.toLocaleLowerCase());
    const words = wordsAll.filter(DISTINCTFUNC);
    const reWordsAll = new RegExp(`\\b(${words.join('|')})\\b`, 'gmi');
    const matchWords = textContent.toLocaleLowerCase()
                        .match(reWordsAll) || [];

    return matchWords.filter(DISTINCTFUNC).length === words.length;
  }

  private rulesHtmlContent(data: SEOConfiguration): Array<IRuleSeoConfiguration> {
    const rules: Array<IRuleSeoConfiguration> = [];
    const contentHtml = data.contentHtml;
    const keywords = StringUtil
                      .removeSpecialChars(`${(data.focusKeyword) || ''} ${(data.secondaryKeywords) || ''}`)
                      .trim();
    if (keywords) {
      const existH1 = contentHtml.querySelector('h1');
      const h1Rules = RULES_SEO.filter(r => {
        const rulesCode = [
          RULES_SEO_CODE.existH1InContent,
          RULES_SEO_CODE.focusKeywordIsUedInH1
        ];

        return rulesCode.includes(r.code);
      });
      h1Rules.forEach(rule => {
        const value = (existH1 && rule.code !== RULES_SEO_CODE.existH1InContent)
                        ? this._existKeywordsInContent(keywords, existH1.textContent)
                        : !!existH1;
        const description = (value)
                              ? rule.description
                              : rule.descriptionError;
        rules.push({
          code: rule.code,
          description: description.replace(/{{focusKeyword}}/g, keywords),
          value
        } as IRuleSeoConfiguration);
      });

      const existImages = contentHtml.querySelectorAll('img');
      const imageRules = RULES_SEO.filter(r => {
        const rulesCode = [
          RULES_SEO_CODE.existImage,
          RULES_SEO_CODE.focusKeywordIsUedInImageName,
          RULES_SEO_CODE.focusKeywordIsUedInImageTag
        ];

        return rulesCode.includes(r.code);
      });
      imageRules.forEach(rule => {
        let value;
        if (rule.code === RULES_SEO_CODE.existImage) {
          value = !!existImages.length;
        } else {
          const imagesFilter = Object.keys(existImages)
                                .filter(img => {
                                  const textContent = (rule.code === RULES_SEO_CODE.focusKeywordIsUedInImageName)
                                                        ? StringUtil.getFileName(existImages[img].src)
                                                          .replace(/-/gi, ' ')
                                                        : existImages[img].alt;

                                  return this._existKeywordsInContent(keywords, textContent);
                                });
          value = existImages.length && imagesFilter.length === existImages.length;
        }
        const description = (value)
                              ? rule.description
                              : rule.descriptionError;
        rules.push({
          code: rule.code,
          description: description.replace(/{{focusKeyword}}/g, keywords),
          value
        } as IRuleSeoConfiguration);
      });

      const existsLink = contentHtml.querySelectorAll('a');
      const hostname = StringUtil.subStringUntilCharacter(data.urlSite, '/');
      const linkRules = RULES_SEO.filter(r => {
        const rulesCode = [
          RULES_SEO_CODE.existsLinkInternal,
          RULES_SEO_CODE.existsLinkExternal
        ];

        return rulesCode.includes(r.code);
      });
      linkRules.forEach(rule => {
        const linksFilter =  Object.keys(existsLink)
                              .filter(link => {
                                return (rule.code === RULES_SEO_CODE.existsLinkInternal)
                                          ? existsLink[link].hostname === hostname
                                          : existsLink[link].hostname !== hostname;
                              });
        rules.push({
          code: rule.code,
          description: linksFilter.length
                        ? rule.description
                        : rule.descriptionError,
          value: !!linksFilter.length
        } as IRuleSeoConfiguration);
      });
    }

    return rules;
  }

  getMetaData(): IMetadata {
    const data = this.seoConfigurationSource.getValue();

    return {
      title: data.title,
      titleSnippet: data.titleSnippet,
      description: data.description,
      ogUrl: `${data.urlSite}/${data.slug}`.replace(/\/+/g, '/'),
      ogTitle: data.social.ogTitle,
      ogDescription: data.social.ogDescription,
      ogImage: data.social.ogImage,
      ogSitename: data.siteName,
      twitterTitle: data.social.twitterTitle,
      twitterDescription: data.social.twitterDescription,
      twitterImage: data.social.twitterImage,
      canonicalLink: data.canonicalLink,
      keywords: data.focusKeyword,
      noindex: data.noindex,
      nofollow: data.nofollow,
      avancedRobots: data.avancedRobots
    } as IMetadata;
  }

}
