import { environment } from '@bvl-admin/environments/environment';

export class UserEndpoint {
  public static usersList = `${environment.LOAD_BALANCER}/user/list`;
  public static saveUser = `${environment.LOAD_BALANCER}/user/save`;
  public static updateUser = `${environment.LOAD_BALANCER}/user/update`;
  public static deleteUser = `${environment.LOAD_BALANCER}/user/delete`;
  public static bvlUsersList = `${environment.LOAD_BALANCER}/user/listUserAD`;
  public static userPermissions = `${environment.LOAD_BALANCER}/user/permision/getOptions`;
}
